import SwaggerUIBundle from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'
import './main.css'
import React from "react"

// Create the layout component
class AugmentingLayout extends React.Component {
  render() {
    const {
      getComponent
    } = this.props

    const BaseLayout = getComponent("BaseLayout", true)

    return (
      <div className="swagger-section">
        <div className="header">
          <div className="swagger-ui-wrap">
            <a
              className="logo"
              title="Ubermetrics Technologies GmbH - HomePage"
              href="https://www.ubermetrics-technologies.com">
            </a>
          </div>
        </div>
        <div className="swagger-ui-wrap">
          <BaseLayout />
        </div>
      </div>
    )
  }
}

// Create the plugin that provides our layout component
const AugmentingLayoutPlugin = () => {
  return {
    components: {
      AugmentingLayout: AugmentingLayout
    }
  }
}

SwaggerUIBundle({
  dom_id: '#myDomId',
  url: "/doc/swagger",
  presets: [
    SwaggerUIBundle.presets.apis,
    SwaggerUIBundle.SwaggerUIStandalonePreset
  ],
  plugins: [ AugmentingLayoutPlugin ],
  layout: "AugmentingLayout",
})
